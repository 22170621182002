<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="mini" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="initData()">
          <el-row class="vg_mb_5">
            <el-col :md="14">
              <el-form-item label="应收日期:">
                <div class="block">
                  <el-date-picker
                    size="small"
                    class="topDateRangeSearch vg_mr_8"
                    v-model="dateRange1"
                    :picker-options="pickerOptions"
                    type="daterange"
                    value-format="timestamp"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  />
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="setlectStatsNow()" class="vg_ml_16">查询 </el-button>
                <el-button class="vd_export" size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
                <el-button v-if="designatedPersonnel" class="vg_ml_16" size="small" type="warning" @click="exportTranCustGroup()">导出汇总</el-button>
                <el-button v-if="designatedPersonnel" class="vg_ml_16" size="small" type="warning" @click="exportTranCustDetail()">导出明细</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-button-group class="vg_mr_8" style="position: relative; top: -1px">
        <el-button size="small" type="primary" @click="getStatsNow(1)">客户组</el-button>
        <el-button size="small" type="primary" @click="getStatsNow(2)">非客户组</el-button>
      </el-button-group>
      <el-row>
        <el-col :md="24">
          <DynamicUTable
            ref="multiTable"
            :table-data="tableData"
            :tableRowKey="`cust_group`"
            :columns="tableProperties"
            v-loading="loadFlag"
            :totalPage="totalPage"
            @row-click="rClick"
            @getTableData="getDequsNow"
            :need-search="true"
            :autoHeight="true"
            :need-check-box="false"
          />
        </el-col>
      </el-row>
      <el-row style="text-align: right">USD合计：{{ this.total_usd }}</el-row>
      <el-row style="text-align: right">RMB合计：{{ this.total_rmb }}</el-row>
      <el-row>
        <el-col :md="24">
          <span><h2>应收明细</h2></span>
          <DynamicUTable
            ref="multiTable2"
            :table-data="tableData2"
            :tableRowKey="`tran_id`"
            :columns="tableProperties2"
            v-loading="loadFlag2"
            :totalPage="totalPage2"
            @getTableData="getDequsNow2"
            :show-summary="true"
            :need-search="true"
            :need-check-box="false"
            :summariesColumns="['tran_prtotal', 'fees_usd_minus', 'fees_usd_plus', 'tran_actual_price']"
          >
          </DynamicUTable>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get } from '@api/request';
import { stisAPI } from '@api/modules/stis';
import helper from '@assets/js/helper.js';
import { BigNumber } from 'bignumber.js';
import SearchTable from '@/components/table/SearchTableV2.vue';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { tableProperties, tableProperties2 } from '@/views/SettleManagement/CusaManage/Cusa';
import { cloneDeep, debounce } from 'lodash';
import { downloadFile } from '@api/public';

export default {
  name: 'CusaList',
  components: { SearchTable, DynamicUTable },
  data() {
    return {
      tableData: [],
      tableData2: [],
      searchForm: {
        cust_id: null,
        timeValue: []
      },
      totalPage: 0,
      totalPage2: 0,
      btn: {},
      loadFlag: false,
      loadFlag2: false,
      custType: 1,
      cust_currency: '',
      cust_abbr: '',
      cust_group: '',
      multiSelection: [],
      custOptionList: [],
      tableProperties: tableProperties,
      tableProperties2: tableProperties2,
      dateRange1: [],
      total_usd: 0.0,
      total_rmb: 0.0,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      }
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    designatedPersonnel() {
      return [11, 72, 8, 1].includes(this.$cookies.get('userInfo').stff_id);
    }
  },
  filters: {
    formatDate1(row) {
      return helper.toTimeDay(row);
    }
  },
  methods: {
    // 查询方法
    getDequsNow: debounce(function () {
      this.loadFlag = true;
      this.currentPage = 1;
      this.getStatsList(this.custType);
    }, 1000),
    // 详情查询方法
    getDequsNow2: debounce(function () {
      this.loadFlag2 = true;
      this.currentPage = 1;
      this.getTranCustDetai();
    }, 1000),
    initData() {
      this.getStatsList(1);
    },
    //客户组查询
    getStatsList(val) {
      if (val === 1) {
        this.tableProperties.find(({ prop }) => prop === 'cust_group').label = '客户组别';
        //更改明细字段显示
        this.tableProperties2.find(({ prop }) => prop === 'cust_abbr').visible = true;
        setTimeout(() => this.$refs.multiTable2.$refs.multiTable.refreshColumn());
        //客户组
        let searchForm = this.$refs.multiTable.searchForm;
        let [startTime, endTime] = this.dateRange1 || [];
        searchForm.start_time = startTime ? startTime / 1000 : null;
        searchForm.end_time = endTime ? endTime / 1000 : null;
        get(stisAPI.getTranCustGroupList, searchForm)
          .then(res => {
            if (res.data.code === 0) {
              this.loading = false;
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total;
              this.custType = res.data.data.cust_type;
              this.total_usd = res.data.data.total_usd;
              this.total_rmb = res.data.data.total_rmb;
              setTimeout(() => {
                this.loadFlag = false;
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            }
          })
          .catch(() => {});
      } else {
        //更改客户组字段名称
        this.tableProperties.find(({ prop }) => prop === 'cust_group').label = '客户简称';
        //更改明细字段显示
        this.tableProperties2.find(({ prop }) => prop === 'cust_abbr').visible = false;
        setTimeout(() => this.$refs.multiTable2.$refs.multiTable.refreshColumn());
        //非客户组
        let searchForm = this.$refs.multiTable.searchForm;
        let [startTime, endTime] = this.dateRange1 || [];
        let start = startTime ? startTime : null;
        let end = endTime ? endTime : null;
        searchForm.startTime = parseInt(start / 1000) === 0 ? null : parseInt(start / 1000);
        searchForm.endTime = parseInt(end / 1000) === 0 ? null : parseInt(end / 1000);
        get(stisAPI.getTranNotCustGroupList, searchForm)
          .then(res => {
            if (res.data.code === 0) {
              this.loading = false;
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total;
              this.custType = res.data.data.cust_type;
              this.total_usd = res.data.data.total_usd;
              this.total_rmb = res.data.data.total_rmb;
              setTimeout(() => {
                this.loadFlag = false;
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
            }
          })
          .catch(() => {});
      }
    },
    //单机客户组
    rClick(val) {
      let searchForm = cloneDeep(this.$refs.multiTable2.searchForm);
      searchForm.cust_type = this.custType;
      this.cust_abbr = val.cust_abbr;
      this.cust_group = val.cust_group;
      this.cust_currency = val.cust_currency;
      searchForm.cust_abbr = this.cust_abbr;
      searchForm.cust_group = this.cust_group;
      searchForm.dim_type = 0; //是否模糊查询 0否1是
      let [startTime, endTime] = this.dateRange1 || [];
      let start = startTime ? startTime : null;
      let end = endTime ? endTime : null;
      searchForm.startTime = parseInt(start / 1000) === 0 ? null : parseInt(start / 1000);
      searchForm.endTime = parseInt(end / 1000) === 0 ? null : parseInt(end / 1000);
      searchForm.cust_currency = val.cust_currency;
      searchForm.page_no = 1;
      get(stisAPI.getTranCustDetailList, searchForm)
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData2 = res.data.data.list;
            this.totalPage2 = res.data.data.total;
            setTimeout(() => {
              this.loadFlag2 = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    //详情list
    getTranCustDetai() {
      let searchForm = cloneDeep(this.$refs.multiTable2.searchForm);
      searchForm.cust_type = this.custType;
      searchForm.cust_group = this.cust_group;
      searchForm.dim_type = 1; //是否模糊查询 0否1是
      let [startTime, endTime] = this.dateRange1 || [];
      let start = startTime ? startTime : null;
      let end = endTime ? endTime : null;
      searchForm.startTime = parseInt(start / 1000) === 0 ? null : parseInt(start / 1000);
      searchForm.endTime = parseInt(end / 1000) === 0 ? null : parseInt(end / 1000);
      searchForm.cust_currency = this.cust_currency;
      get(stisAPI.getTranCustDetailList, searchForm)
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData2 = res.data.data.list;
            this.totalPage2 = res.data.data.total;
            setTimeout(() => {
              this.loadFlag2 = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 刷新
    buttonRefresh() {
      this.dateRange1 = [];
      this.loadFlag = true;
      this.$refs.multiTable2.resetFields();
      this.$refs.multiTable.resetFields();
      this.getStatsList(this.custType);
    },
    //切换客户组
    getStatsNow(val) {
      this.loadFlag = true;
      this.getStatsList(val);
    },
    // 查询方法
    setlectStatsNow() {
      this.loadFlag = true;
      this.getStatsList(this.custType);
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    //导出汇总
    exportTranCustGroup() {
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let searchForm = this.$refs.multiTable.searchForm;
          searchForm.cust_type = this.custType;
          get(stisAPI.exportTranCustGroup, searchForm)
            .then(data => {
              if (data.data.code === 0) {
                downloadFile({ fileUrl: data.data.data.url, fileName: data.data.data.fileName });
                return;
              }
              this.$message.error(data.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    },
    //导出详情
    exportTranCustDetail() {
      if (this.tableData2.length === 0) {
        let mg = '应收明细为空';
        let tp = 'error';
        this.$message({ message: mg, type: tp });
        return;
      }
      this.$confirm('确定下载Excel?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let searchForm = cloneDeep(this.$refs.multiTable2.searchForm);
          searchForm.cust_type = this.custType;
          searchForm.cust_group = this.cust_group;
          get(stisAPI.exportTranCustDetailList, searchForm)
            .then(data => {
              if (data.data.code === 0) {
                downloadFile({ fileUrl: data.data.data.url, fileName: data.data.data.fileName });
                return;
              }
              this.$message.error(data.data.msg);
            })
            .catch(err => console.error(err));
        })
        .catch(err => console.error(err));
    },
    //列总和
    getSummaries(param) {
      const { columns, data } = param;
      let totalList = [];
      for (let i = 0; i < 41; i++) {
        let temp = '';
        if (i === 0) {
          temp = '总计:';
        } else {
          temp = '';
        }
        totalList.push(temp);
      }
      columns.forEach((column, index) => {
        let datas = data;
        let totalA = new BigNumber(0);
        let totalB = new BigNumber(0);
        let totalC = new BigNumber(0);
        let totalD = new BigNumber(0);
        for (let i in datas) {
          totalA = totalA.plus(datas[i].tran_fetotal ? datas[i].tran_fetotal : 0).plus(datas[i].tran_prtotal ? datas[i].tran_prtotal : 0);
          totalB = totalB.plus(datas[i].tran_fetotal ? datas[i].tran_fetotal : 0);
          totalC = totalC.plus(datas[i].positive_tran_fetotal ? datas[i].positive_tran_fetotal : 0);
          totalD = totalD.plus(datas[i].tran_prtotal ? datas[i].tran_prtotal : 0);
        }
        totalA = totalA.toNumber();
        totalB = totalB.toNumber();
        totalC = totalC.toNumber();
        totalD = totalD.toNumber();
        totalList[1] = this.helper.haveFour(totalA);
        totalList[2] = this.helper.haveFour(totalB);
        totalList[3] = this.helper.haveFour(totalC);
        totalList[4] = this.helper.haveFour(totalD);
      });
      return totalList;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

::v-deep .vd_posi_t {
  position: absolute;
  top: 5px;
  left: 30px;
  font-size: 24px;
  color: red;
}
</style>
