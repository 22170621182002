import { getDateNoTime } from '@assets/js/dateUtils';

export const tableProperties = [
  { label: '客户组别', prop: 'cust_group', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '所属部门', prop: 'dept_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '币种', prop: 'cust_currency', itemType: 'input', input: true, sortable: true, widthAuto: true },
  { label: '应收款', prop: 'tran_actual_amount', itemType: 'input', input: true, sortable: true, widthAuto: true }
];
export const tableProperties2 = [
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true, visible: true },
  { label: '出运发票号', prop: 'tran_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '出运金额', prop: 'tran_prtotal', itemType: 'input', input: false, sortable: true, widthAuto: true },
  { label: '费用金额（扣减）', prop: 'fees_usd_minus', itemType: 'input', input: false, sortable: true, widthAuto: true },
  { label: '费用金额（增加）', prop: 'fees_usd_plus', itemType: 'input', input: false, sortable: true, widthAuto: true },
  { label: '实际金额', prop: 'tran_actual_price', itemType: 'input', input: false, sortable: true, widthAuto: true },
  { label: '出运总量', prop: 'tran_prod_ship', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '离港日',
    prop: 'tran_dpdate',
    itemType: 'input',
    input: false,
    sortable: true,
    widthAuto: true,
    formatter: val => getDateNoTime(val, true)
  }
];
